.footer-social {
    padding: 12px 14px;
}

.footer-social__header,
.footer-static-block h3 {
    text-transform: uppercase;
    margin-bottom: 10px;
}

.site-footer-top-level__row {
    display: block;
}

.site-footer-top-level-inner {
    min-width: 0;
}

.footer-gifts {
    padding: 0 14px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gift-block, .bonus-card-block{
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
}

.footer-return-desktop {
    display: block;
    padding: 12px 14px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}
.footer-return-desktop__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
