.h2 {
    font-size: 1.375rem;
    font-weight: normal;
    margin: 0 0 1rem 0;
}
.mobile-header {
    display: flex;
    align-items: center;
    padding: 0 8px;
    z-index: 10;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: transparent;
}
.mobile-header__item {
    padding: 8px;
    box-sizing: border-box;
}

.mobile-header__menu-triger {
    cursor: pointer;
    width: 24px;
    height: 16px;
    position: relative;
    transform: rotate(0deg);
    transition: transform .5s ease-in-out;
    display: block;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: currentColor;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: transform .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2), &:nth-child(3) {
            top: 7px;
        }

        &:nth-child(4) {
            top: 14px;
        }
    }
}

.mobile-header__menu-triger--opened span {
    &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    &:nth-child(2) {
        transform: rotate(45deg);
    }

    &:nth-child(3) {
        transform: rotate(-45deg);
    }

    &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}

.mobile-header__search-btn {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.mobile-header__icon {
    vertical-align: middle;
}

.mobile-header__logo-block {
    text-align: center;
    flex-grow: 1;
    max-height: 100%;
    height: 100%;
}

.mobile-header__phone-block{
    min-width: 34px;
}

.mobile-header__cart-link {
    position: relative;
}
.mobile-header__cart-link,
.mobile-header__phone-link,
.header-link {
    color: currentColor;

    &:hover {
        color: currentColor;
    }
}

.mobile-header__cart-count {
    position: absolute;
    border-radius: 50%;
    font-size: 8px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -6px;
    right: -6px;
    padding: 0 2px;
    box-sizing: border-box;
}

.mobile-header--visible-on-search-active {
    display: none;
}

.mobile-header--search-active {
    .mobile-header--hidden-on-search-active {
        display: none;
    }

    .mobile-header--visible-on-search-active {
        display: block;
    }
}


.mobile-header__search-form-block {
    flex-grow: 1;
}

.mobile-header__search-form {
    display: flex;
    align-items: center;
}

.mobile-header__search-form-item {
    padding: 8px;
}

.mobile-header__search-form-input-block {
    flex-grow: 1;
}

.mobile-header__search-form-input {
    border: 0;
    height: 32px;
}

.mobile-header__search-form-back {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}

.mobile-header__search-form-back-icon {
    vertical-align: middle;
}


/*phones-list*/
.mobile-header {
    &__phones-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: #fff;
    }

    &__phones-list-btn {
        background-color: transparent;
        border: 0;
        color: currentColor;
    }
}

.mobile-header__search-form-back, .mobile-header__search-btn {
    color: currentColor
}
