.cookies-policy {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 55;
}

.cookies-policy-block {
    padding: 0.3125rem;
    font-size: 0.875rem;
    width: 100%;
    box-sizing: border-box;
}

.cookies-policy-cell {
    vertical-align: middle;
    padding: 10px;
}

.cookies-policy-button {
    text-align: center;
    padding: 10px 0;
}

.cookies-policy-close {
    display: none;
}

.cookies-policy__btn-close {
    font-size: 0.75rem;
    padding: 0.75rem;
    width: 100%;
}
