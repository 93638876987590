.site-footer-top-level {

    .footer-menu,
    .footer-gifts__wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .footer-menu + .footer-menu {
        margin-top: -1px;
    }

    .footer-menu-head {
        font-size: 14px;
        padding: 12px 24px 12px 14px;
        display: block;
        position: relative;
        border-width: 1px 0;
        border-style: solid;
    }

    .footer-menu__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
        display: block;
        width: 10px;
        height: 10px;
    }

    .accordion-css__state:checked ~ .footer-menu-head .footer-menu__arrow {
        transform: translateY(-50%) rotate(90deg);
    }

    .footer-menu-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .footer-menu-item {
        padding: 10px 14px;
        box-sizing: border-box;
    }

    .footer-menu-icon-block {
        display: inline-block;
        margin-right: .3125rem;
        line-height: 0;
        font-size: 0;
        vertical-align: middle;
    }
}
