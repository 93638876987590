.select-custom {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #fff;
    box-sizing: border-box;

    &:after {
        right: 10px;
        pointer-events: none;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        /* do not forget set background color to select by color-sheme */
        /*border: none;*/
        border-color: transparent;
        padding: 8px 30px 8px 15px;
        border-radius: 5px;
        width: 100%;
        cursor: pointer;

        &:hover, &:focus {
            /*border: none;*/
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }

        option {
            background-color: #fff;
            color: #333;
            border: none;
        }
    }

    &--slim select {
        padding: 0 30px 0 15px;
        height: 25px;
    }

    &--fat select {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
