.btn{
    box-sizing: border-box;
    text-transform: uppercase;
}

.btn-middle{
    font-size: 12px;
}

.btn-ghost {
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 1.25rem auto 0;
    width: 13px;
    height: 13px;
    color: #adadad;
}

