@import "../_defFuncValues.scss";
@import "../_function.scss";
@import "../variables.scss";
@import "../../../../styles/spinner.scss";

body.sidebar_open {
    left: toEmSize(340px, $desWidth);
    position: fixed;
}

#sidebar,
.sidebar {
    transition: transform 0.1s;
    transform: translate3d(-100%, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    height: var(--min-full-height, 100vh);
    z-index: 110;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: toEmSize(340px, $desWidth);
}

#sidebar {
    background: #3a3c44;
}

.sidebars-container {
    position: relative;
    z-index: 10;
}

.sidebar_open #sidebar,
.sidebars-container--activated .sidebar,
.sidebar-state-menu__input:checked + .sidebars-container .sidebar {
    transform: translate3d(0,0,0);
}

#sidebar_overlay,
.sidebar_overlay {
    background: rgba(0,0,0,0.2);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;
}

.sidebar_open #sidebar_overlay,
.sidebars-container--activated .sidebar_overlay,
.sidebar-state-menu__input:checked + .sidebars-container .sidebar_overlay {
    display: block;
}

#sidebar .head {
    padding: 0 12px;
    padding-left: 23px;
    line-height: toEm(58px, 18px);
    background: #232429;
    font-size: toEm(18px);
    display: block;

    img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        border-radius: 4px;
    }

    .username {
        max-width: 190px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.sidebar__header {
}

.sidebar__close {
    border: 0;
    background-color: transparent;
    margin: 0;
    padding-right: 0;
}

.sidebar-content-static {
    visibility: hidden;
}

.sidebar__content-static--open,
.sidebar-state-menu__input:checked + sidebars-container [data-content-id="sidebarMenu"] {
    visibility: visible;
}

.sidebar--open-root {
    overflow: hidden;
}

@media #{$viewport-sm} {

    body.sidebar_open {
        left: 65%;
    }

    #sidebar,
    .sidebar {
        width: 65%;
    }
}


@media #{$viewport-md} {

    body.sidebar_open {
        left: 60%;
    }

    #sidebar,
    .sidebar {
        width: 60%;
    }
}

body.sidebar_open {
    left: auto;
    position: static;
}

#sidebar, #sidebar_overlay,
.sidebar, .sidebar_overlay {
    top: 51px;
    height: calc(var(--min-full-height, 100vh) - 51px);
}

.sidebar__header {
    padding: 16px 16px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
