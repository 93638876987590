.phones-number-list {
    &__item {
        display: grid;
        grid-template-columns: 25px auto;
        grid-column-gap: 10px;
        padding: 22px 18px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        align-items: center;

        &:hover {
            text-decoration: none;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__icon {
        height: 25px;
        width: 25px;
        /*svg {
            height: 18px;
            width: 18px;
        }*/
    }

    &__item-tel {
        font-size: 14px;
        font-weight: bold;
    }

    &__item-descr {
        font-size: 12px;
        font-weight: normal;
        padding-top: 3px;
    }
}
