.carousel-dots-item-inner {
    box-shadow: none;
}

.carousel-dots {
    margin-top: 5px;

    .carousel-dots-item-inner {
        box-shadow: inset 0 0 5px 0 rgba(0,0,0, .5);
    }

    .cs-selected {
        box-shadow: none;
    }
}

.carousel-dots-item {
    vertical-align: middle;
    opacity: 1;
}

.carousel-dots-selected {
    transform: scale(1.2);
}
