@import "../_defFuncValues.scss";
@import "../_function.scss";

.menu {
    list-style: none;

    &__link {
        cursor: pointer;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    &__item {
        padding: 0 24px;
        display: block;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        text-align: left;
        line-height: toEm(48);
        overflow: hidden;
        position: relative;

        img {
            display: inline-block;
            vertical-align: middle;
            width: 20px;
        }

        .newOrders {
            padding: 4px 8px;
            line-height: toEm(18);
            font-size: toEm(14);
            float: right;
            border-radius: 15px;
        }
    }
}

.menu--root {
    transition: transform .5s;
}

.menu__item {
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    position: static;
    line-height: 1.2;

    &:last-child {
        border-bottom: 0;
    }
}

.menu__right-block {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    display: block;
}

.menu__loading {
    animation: menuSpin 2s infinite linear;
    transform-origin: center center;
}

@keyframes menuSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.menu__item-inner,
.menu__link--thin {
    position: relative;
}

.menu__item-inner {
    padding-left: 16px;
    padding-right: 24px;
}

.menu__item-inner--in-submenu {
    padding-left: 32px;
}

.menu__link--thin {
    display: block;
    height: 100%;
    flex-basis: 100%;
}

.menu__item-inner,
.menu__link--with-icon {
    display: flex;
    align-items: center;
}

.menu__submenu {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 100%;
    width: 100%;
    box-sizing: border-box;
    top: 0;
}

.menu__city,
.menu__currency {
    font-weight: 600;
    text-decoration: underline;
    background-color: transparent;
    padding: 0;
    border: 0;

    &:hover {
        text-decoration: none;
    }
}

.menu__link {
    padding-top: 14px;
    padding-bottom: 14px;
}

.mobile-header__logo-link {
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    height: 100%;
}

.menu__item--clone {
    /*position: absolute;
    left: 100%;
    top: 0;*/
}

.menu__icon {
    margin-left: 6px;
}

.menu__arrow-left {
    margin-right: 6px;
}

.currency-change__select {
    opacity: 0;
}

.currency-change__wrap {
    width: 100%;
}

.currency-change {
    display: flex;
    align-items: center;
}

.currency-change__select-block {
    padding-left: 5px;
    flex-grow: 1;
}


.menu__currency {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.currency-change__select-block {
    position: relative;
}

.menu__link--all {
    font-weight: 600;
}

.link-img-mobile__app {
    display: block;
    width: 100%;
    max-width: 140px;
}

.menu__category-icon,
.menu-icon-cms {
    padding-right: 10px;
    width: 20px;
}

.menu__link-inside {
    display: flex;
    align-items: center;
}
