.cart-mini-list-mobile {
    .cart-mini-block {
        position: static;
        top: auto;
        right: auto;
        padding-top: 0;
        box-shadow: none;
        border-radius: 0;
        min-width: 0;
    }

    .cart-mini-result-block {
        background-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .cart-mini-scrollbar {
        max-height: none;
    }

    .cart-mini-item {
        padding-left: 0.375rem;
        padding-right: 60px;
        position: relative;
    }

    .cart-mini-item + .cart-mini-item {
        border-top-width: 1px;
        border-top-style: solid;
    }

    .cart-mini-remove {
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 21px;
    }

    .cart-mini-photo {
        padding-left: 0;
        padding-right: 0;
    }

    .cart-mini-photo, .cart-mini-info {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .cart-mini-info {
        font-size: 12px;
    }

    .cart-mini-result-name {
        font-weight: normal;
    }

    .cart-mini-result-value {
        font-size: 14px;
    }

    .cart-mini-result-total-price {
        font-weight: bold;
    }

    .cart-mini-result-row {
        text-align: right;
    }

    .cart-mini-result-block {
        border-top-width: 1px;
        border-top-style: solid;
    }

    .cart-mini-scrollbar-block {
        padding-top: 0;
        padding-bottom: 0;
    }

    .cart-mini-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }

    .cart-mini-btn-cart, .cart-mini-btn-checkout {
        width: 48%;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}