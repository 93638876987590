.products-by-ids__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.66667em 1em;
}
.products-by-ids__title {
    font-size: 20px;
}
.products-by-ids__item {
    padding-right: 13px;
    padding-left: 13px;
    border: 0;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
}