.sidebar__social-links {
    padding: 2px 7px 16px;

    .footer-menu-head,
    .footer-menu-link:not(.social) {
        display: none;
    }

    .footer-menu-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .footer-menu-item {
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
    }
}
