@import "./_defFuncValues.scss";
@import "./_function.scss";

html {
    font-size: $hfs;
    /*height: 100%;
    min-height: 100%;*/
}

body {
    /*color: #3a3c44;*/
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
    /*height: 100%;
    min-height: 100vh;*/
    position: relative;
    transition: left 0.1s;
}

iframe {
    max-width: 100%;
    max-height: 100%;
}

label {
    position: relative;
    display: inline-block;
}

.wrapper {
    /*height: 100%;*/
    position: relative;
}



#layout {
    /*min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;*/
    position: relative;
    z-index: 0;
}

a {
    text-decoration: none;
    //tap highlighting color on touchdevice
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.img-not-resize{
    max-height: none;
    max-width: none;
}

.input-wrap {
    width: 100%;
    display: inline-block;

    &.login {
        border-bottom: 1px solid #cdcdcd;
    }

    input {
        padding: 19px 0;
    }
}

#header {
    position: sticky;
    top: 0;
    z-index: 51;
    height: 50px;
}

.nav-root {
    font-weight: bold;
    font-size: toEm(16);
    text-align: center;
    padding: 0 0;
}

.nav-root {
    text-align: center;
    padding: 0 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100px;
    left: 100px;
    margin: auto auto;
}


.toggle_sidebar {
    height: 50px;
    width: 50px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
}

.toggle_sidebar.active {
    left: 0;
    right: auto;
}

.toggle-sidebar-icon {
    position: absolute;
    font-size: toEm(15);
    font-weight: normal;
    width: 20px;
    height: 14px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto auto;
}

.phoneBtn {
    position: absolute;
    height: 50px;
    width: 50px;
    right: 60px;
    top: 0;

    .phone-link {
        box-sizing: content-box;
        position: absolute;
        height: 16px;
        width: 16px;
        margin: auto;
        padding: 15px;
        padding-top: 12px;
        padding-bottom: 18px;
        border-radius: 50%;
        font-size: toEm(16);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-decoration: none;
        color: currentColor;

        &:hover {
            text-decoration: none;
        }
    }
}

.cartBtn {
    position: absolute;
    height: 50px;
    width: 60px;
    right: 15px;
    top: 0;

    .cart-link {
        position: absolute;
        text-decoration: none;
        border-radius: 50%;
        font-size: toEm(16);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: currentColor;

        &:hover {
            text-decoration: none;
        }
    }

    .cart-count {
        position: relative;
        border-width: 1px;
        border-style: solid;
        padding: toEm(2) toEm(5);
        display: inline-block;
        font-family: Arial;
        border-radius: 2px;

        .border {
            content: "";
            position: absolute;
            left: -3px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 3px;
            height: 3px;
            border-width: 1px;
            border-right-width: 0;
            border-bottom-width: 0;
            border-style: solid;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
}

menu {
    padding: 0;
    margin: 0;
}

.myaccount-link {
    padding: 0 24px;
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-align: left;
    line-height: toEm(48);
    color: white;
    border-color: #484a52;
    overflow: hidden;
    position: relative;
    font-size: toEm(18);

    &:hover, &:active {
        background: #232429;
        text-decoration: none;
    }
}

.price {
    display: block;
    font-size: toEm(18);
    margin-top: toEm(5);
    line-height: 1.5;
}

.cart-popup-carousel .price {
    line-height: 1;
}

.bonus-string-sum {
    height: 0; //чтобы слева от бонусов не было пробела
    margin-top:-18px;
}

/*.bonus-string-sum--top {
    margin-top: -18px;
}*/

.price-old {
    text-decoration: line-through;
    display: block;
    font-weight: normal;

    .price-number, .price-currency {
        text-decoration: line-through;
    }
}

.price-current {
    display: block;
}

.price-discount {
    font-size: 11px;
}

.prod-photo {
    display: inline-flex;
    width: 40%;
    vertical-align: middle;
    margin: 0 4% 0 0;
    text-align: center;
    position: relative;
    align-items: center;
}

.prod-name {
    font-size: toEm(18);
}


.prod-text {
    display: inline-block;
    width: 54%;
    vertical-align: middle;
}

.product-view-mobile-tile__item .prod-text {
    width: 100%;
}

.catalog-product-item .prod-colors-wrap {
    margin-top: 10px;
}

.product-view-mobile-tile__item .prod-colors-wrap {
    display: flex;
    justify-content: center;
}

.product-view-mobile-tile__item .prod-colors{
    flex-basis: 100%;
    max-width: 100%;
}
.product-view-mobile-tile__item .carousel-inner{
    margin-left: auto;
    margin-right: auto;
}
.city-selector-input {

    input[type="text"] {
        border: none;
        text-indent: toEm(10);
        padding: toEm(7) 0 toEm(1);
        padding-top: toEm(5);
        /*height: toEm(32px);*/
    }

    &.active + .city-selector-panel {
        margin-top: toEm(10);
        /*height: toEm(150px);
        -moz-transition: height 0.25s ease-in;
        -o-transition: height 0.25s ease-in;
        -webkit-transition: height 0.25s ease-in;
        transition: height 0.25s ease-in;*/
    }
}

.city-selector-panel {
    /*height: 0;*/
    /*-moz-transition: height 0.15s ease-out;
    -o-transition: height 0.15s ease-out;
    -webkit-transition: height 0.15s ease-out;
    transition: height 0.15s ease-out;*/
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    overflow-y: scroll;

    a {
        border: none;
    }
}



.footer-links {
    text-align: center;

    .footer-link {
        font-size: toEm(12);
    }
}

.carousel-mobile-dots {
    margin-bottom: 6px !important;
}

.good-select-emulate-text {
    font-family: Arial;
}

.price-number, .price-currency {
    display: inline-block;
}

.block-usr-txt {
    margin: 10px 0;
}

.pagenumberer {
    text-align: center;
    margin: 20px 0;
}

.page-all {
    display: block;
    float: none !important;
    margin-top: 19px;
    margin-bottom: 10px;
}

.video-embed {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
}

.video-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.block {
    margin: 0 10px;
}

.block-head {
    padding: 10px 20px;
    font-size: 21px;
}
/*Стили для оформления заказов*/
.row .col-xs-4.col-p-v {
    display: none;
}

.mobile-block > label {
    display: block;
}

.zone-dialog-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.zone-dialog-item {
    width: 50%;
    box-sizing: border-box;
}

.mobile-content-padding {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding-right: 18px;
    padding-left: 18px;
}

.page-title {
    /*font-weight: bold;
    font-size: 1.56667em;
    text-align: center;
    padding: 10px 0;*/
}

.shipping-item-additional .shipping-control-select {
    width: 160px;
}
/* https://bugs.chromium.org/p/chromium/issues/detail?id=613885 */
select {
    transition: none;
}

.mobile-container-fluid {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.right-bonus-column {
    padding-top: 5px;
    width: 100%;
}

.bonus-img {
    width: 100%;
    text-align: center;
}

.bonus-img img {
    padding-right: 20px;
    padding-left: 20px;
}

.site-body-cell-to-center {
    padding: 10px;
}

.site-body-cell-to-center .h2 {
    text-align: center;
}

.p-l-xs {
    padding-left: 0.625rem;
}

.p-r-xs {
    padding-right: 0.625rem;
}

.category-description {
    display: block;
    padding-left: 17.333px;
    padding-right: 17.333px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.category-not-found-text {
    margin-top: 20px;
    margin-bottom: 20px;
}

#properties .properties-item > .row > .col-xs-5 {
    width: 41.66667%;
}

.nav-root a {
    color: #fff;
}

.login-block-registration-text {
    margin-bottom: 10px;
}

.login-block-registration {
    padding: 10px;
}

#footer {
    margin-top: 15px;
    border-top: 1px solid #e9e9eb;
}

.mobile-menu-trigger {
    color: #fff;
}

.product-price.details-payment  .half-cell .details-payment-cell,
.product-price.details-payment  .half-cell .details-amount {
    margin-bottom: 20px;
}

.product-price.details-payment .details-payment-inline .btn-action {
    margin-top: 20px;
}

.zone-dialog-link,
.zone-dialog-link:hover {
    color: #fff;
}

.select-custom--fat select {
    padding-top: 15px;
    padding-bottom: 14px;
}

/*.product-view-mobile-list__item .mobile-prod-artno {
    padding: 7px 0;
}*/

.subscribe-block {
    margin-top: 12px;
    /*margin-left: 10px;
    margin-right: 10px;*/
}

.related-product-title-wrap {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.staticpage-title {
    padding-left: 18px;
    padding-right: 18px;
}
/*перенес из styles/views/brands.scss*/
.breads {
    display: none;
}



.scroll-always-visible::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 100px;
    height: 2px;
}

.scroll-always-visible::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.rating {
    line-height: 1;
}

.mobile-tabs-vertical .tabs-header-item-link {
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    text-decoration: none;
    color: currentColor;
}

.spinner {
    position: relative;
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 10px;

    &:before {
        content: "";
        display: block;
        background: rgba(255,255,255, .5);
        width: 100%;
        z-index: 10;
    }
}

.spinner-after:after {
    content: "\e80b";
    left: 50%;
    top: 50%;
    font-size: 35px;
    animation: spinShipping 2s infinite linear;
    display: inline-block;
    text-shadow: none;
    font-family: "advantshopfonts";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    line-height: 1;
    text-align: center;
    opacity: 1;
    font-variant: normal;
    text-transform: none;
}

.get-bonus-card-block {
    .breadcrumbs {
        padding-left: 0;
        padding-right: 0;
    }
}

.get-bonus-card-block .site-body-cell-to-center {
    padding-left: 0;
    padding-right: 0;
}

.product-price.details-payment .details-payment-inline .btn-action {
    margin-top: 12px;
}

.mobile-container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;

    .page-title {
        margin-right: -15px;
        margin-left: -15px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.subscribe-block {
    padding: 10px;
}

.custom-input-native {
    margin-right: 5px;
}

.price {
    font-size: 18px;
}

.price-old {
    font-size: 11px;
}

.price-new {
    margin-bottom: 0;
}

.price-unknown {
    font-size: 16px;
    font-weight: normal;
}
.autocompleter-sub {
    left: 10px !important;
    right: 10px !important;
    min-width: 0;
    max-width: 100vw;
}

.col-p-v-mobile {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

