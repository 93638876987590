body.swal2-toast-shown.swal2-shown .swal2-top.mobile-cart-popover-container {
    left: 0;
    right: 0;
    transform: none;
}

.mobile-cart-popover {
    transform: none;
    left: 0;
    border-radius: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &.swal2-popup.swal2-toast, .swal2-content {
        width: 100%;
    }
}
